.position-relative{
    position: relative;
}
.dropdown-menu.storage-info.storage-view {
    min-width: 720px;
}
.table tr th.mandatory:after {
    content: "*";
    display: inline-block;
    color: #e04361;
    margin-left: 2px
}

.experiment-allocation .list-items .ngx-dropdown-list-container .selected-items{
    display: none !important;
}

// @scope(#dynamicforms){
//     .form-control.date-input:disabled{
//         background-color: #b2b2b2 !important;
//     }
// }